import React from "react"
export default function Obavijest() {
  return (
    <>
      <div>
        <div className="container py-12 lg:py-20">
          <h1 className="mb-6 text-4xl leading-none ">
            OBAVIJEST O GODIŠNJIM ODMORIMA
          </h1>
          <p>Poštovani,</p>
          <p>
            {" "}
            Obavještavamo Vas da Ordinacija Martina Atelj, dr.med. radi
            korištenja godišnjeg odmora neće raditi u periodu: <br />
            <strong>12. 5. 2025. – 16. 5. 2025.</strong>
          </p>
          <p>
            Pacijente s HITNIM potrebama primat će Ordinacija opće medicine
            Marta Despotović, dr.med. <br />
            Radno vrijeme: parni datumi ujutro, neparni popodne <br /> Tel: 023
            311 266
          </p>
          <p>
            <strong>Molimo da na vrijeme naručite kroničnu terapiju!</strong>{" "}
          </p>
          <p>Hvala na razumijevanju.</p>
        </div>
      </div>
    </>
  )
}

function Icon(props) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill="currentColor"
      viewBox="0 0 512 512"
      style={{ enableBackground: "new 0 0 512 512" }}
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g>
          <path
            d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256
			C512,114.497,397.493,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216
			c119.393,0,216,96.615,216,216C472,375.393,375.385,472,256,472z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M256,128.877c-11.046,0-20,8.954-20,20V277.67c0,11.046,8.954,20,20,20s20-8.954,20-20V148.877
			C276,137.831,267.046,128.877,256,128.877z"
          />
        </g>
      </g>
      <g>
        <g>
          <circle cx={256} cy="349.16" r={27} />
        </g>
      </g>
    </svg>
  )
}
